<template>
  <div class="gmv-admin-bank">
    <div class="gmv-admin-bank-container">
      <div>
        <EditBanksModal :selectedBank="selectedBank"></EditBanksModal>
      </div>
      <Loading v-if="isLoading" />
      <div v-if="!isLoading">
        <div class="gmv-admin-bank-container__button">
          <div class="gmv-admin-bank-container__button">
            <Button
              v-b-modal.createBanksModal
              label="Crear banco"
              icon="fas fa-user-plus"
              class="p-button-warning"
            /><CreateBanksModal></CreateBanksModal>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                type="text"
                v-model="searchBanks"
                placeholder="Buscar"
                @input="filterBanks"
              />
            </span>
          </div>
          <div>
            <h6>Bancos</h6>
            <b-avatar
              class="avatar-count"
              :badge="countArray.count.toString()"
              variant="primary"
              badge-variant="danger"
              ><i class="fas fa-university fa"></i
            ></b-avatar>
          </div>
        </div>
        <div>
          <div class="gmv-admin-bank-container__cell">
            <div v-for="(banks, index) in filteredBanks" :key="index">
              <b-card-group class="gmv-admin-bank-container__cards" deck>
                <b-card header-tag="header" footer-tag="footer" class="label">
                  <template #header>
                    <h4 class="label">{{ banks.name }}</h4>
                    <div>
                      <b-button-toolbar class="button-toolbar">
                        <b-dropdown class="button-dropdown" size="sm">
                          <b-dropdown-item
                            class="button-dropdown-item"
                            @click="deleteBanks(banks)"
                            ><i class="fas fa-user-times"></i>
                            Eliminar</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-b-modal.editBanksModal
                            @click="openModal(banks)"
                            ><i class="fas fa-user-edit"></i> Editar
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-button-toolbar>
                    </div>
                  </template>
                </b-card>
              </b-card-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/Loading";
import Button from "primevue/button";
import EditBanksModal from "@/components/EditBanksModal.vue";
import CreateBanksModal from "@/components/CreateBanksModal.vue";
import InputText from "primevue/inputtext";
import { BButtonToolbar } from "bootstrap-vue";
export default {
  inject: ["reload"],
  name: "adminBanks",
  components: {
    Loading,
    Button,
    EditBanksModal,
    CreateBanksModal,
    InputText,
    BButtonToolbar,
  },
  data() {
    return {
      banks: {
        name: null,
      },
      isLoading: false,
      banksArray: null,
      countArray: null,
      selectedBank: null,
      filteredBanks: null,
      searchBanks: "",
    };
  },
  async created() {
    this.isLoading = true;
    await this.getCount();
    let response = await this.$store.dispatch("banks/getBanks");
    this.banksArray = response.data;
    this.filteredBanks = [...this.banksArray];
    this.isLoading = false;
  },
  methods: {
    async getCount() {
      let response = await this.$store.dispatch("banks/getCount");
      this.countArray = response.data;
    },
    async deleteBanks(bankId) {
      this.swal({
        title: "¿Estas Seguro?",
        text: "Una vez eliminado, ¡no podrá recuperar este usuario!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let params = {};
          params.id = bankId.id;
          let result = await this.$store.dispatch("banks/deleteBanks", params);
          if (result.status == 204) {
            this.reload({ name: "BanksList" });
          }
        }
      });
    },
    openModal(banksInfo) {
      this.selectedBank = banksInfo;
    },
    filterBanks() {
      this.filteredBanks = this.banksArray.filter((item) => {
        return item.name.toLowerCase().includes(this.searchBanks.toLowerCase());
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.gmv-admin-bank-container {
  width: 100%;
  height: 100%;
  padding: 1rem 6rem;
  &__cell {
    text-align: initial;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.p-button-warning {
  color: white;
  background: $color-primary-company;
  border: 1px solid $color-primary-company;
  border-radius: 5px;
}
.avatar-count {
  background-color: #1d2124;
}
h6 {
  color: black;
}
::v-deep .p-input-icon-left,
.p-input-icon-right {
  position: relative;
  display: inline-block;
  margin-left: 1rem;
}
.card {
  margin: 10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.425);
  border-radius: 1.25rem;
  width: 20rem;
}
.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.button-toolbar {
  border-radius: 5px;
}
::v-deep .dropdown-toggle {
  width: 22px;
  height: 25px;
  margin: 5px;
  background: #e9ecef;
  border: none;
}
::v-deep .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: red;
  border-color: red;
}
::v-deep .dropdown-toggle:hover {
  background: #dee2e6;
  border: none;
}
::v-deep .dropdown-menu.show {
  padding: 20px;
  margin-left: -80px;
  border-radius: 20px;
}
.p-field-checkbox {
  margin: 5px;
}
.label {
  color: black;
  text-transform: capitalize;
}
::v-deep.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $color-primary-company;
  background: $color-primary-company;
}
::v-deep.p-checkbox .p-checkbox-box.p-highlight:hover {
  border-color: red;
  background: red;
}
.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background-color: red;
  color: black;
  border-color: red;
}
.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: center;
}
</style>
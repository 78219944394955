<template>
  <div class="gmv-edit-modal">
    <b-modal
      class="gmv-edit-modal-container"
      id="editBanksModal"
      scrollable
      title="Editar banco"
    >
      <div>
        <p class="gmv-edit-modal-form__title">
          Todos los campos son requeridos
        </p>
        <div class="gmv-edit-modal-form__inputs">
          <InputText
            type="text"
            placeholder="Nombre completo"
            v-model="banks.name"
          />
        </div>
      </div>
      <template #modal-footer>
        <div class="gmv-edit-modal-button">
          <b-button
            class="gmv-edit-modal-button__btn1"
            size="sm"
            @click="patchBanks(banks)"
          >
            Editar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import InputText from "primevue/inputtext";
export default {
  inject: ["reload"],
  name: "EditBanksModal",
  props: {
    selectedBank: Object
  },
  components: {
    InputText
  },
  data() {
    return {
      banks: {
        name: null
      },
      banksArray: null
    };
  },
  watch: {
    selectedBank() {
      this.banks = { ...this.selectedBank };
    }
  },
  methods: {
    async patchBanks(banksData) {
      let payload = {};
      payload = banksData;
      payload.id = banksData.id;
      let result = await this.$store.dispatch(
        "banks/patchBanks",
        payload,
        this.banks
      );
      if (result.status == 204) {
        this.reload({ name: "Banks" });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 40rem;
  pointer-events: auto;
  background-color: #fff;
  border-radius: 0.3rem;
}
::v-deep .modal.show .modal-dialog {
  transform: none;
  max-width: 40rem;
}
::v-deep button.close {
  border: none;
  background: none;
}
::v-deep .modal {
  top: 60px;
  left: 60px;
}
.gmv-edit-modal-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &__inputs {
    border-radius: 8px;
    margin: 10px 0px 15px 60px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 30rem;
  }
  &__title {
    color: $color-primary-company;
    text-align: center;
    padding-top: 15px;
  }
}
::v-deep .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1 rem;
  display: flex;
  flex-direction: column;
}
.gmv-edit-modal-button {
  display: flex;
  justify-content: space-evenly;
  &__btn1 {
    background-color: $color-primary-company;
    height: 40px;
    border: 1px solid $color-primary-company;
    width: 120px;
    border-radius: 10px;
  }
  &__btn1:hover {
    background-color: red;
    color: black;
  }
}
::v-deep .modal-footer {
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
}
</style>